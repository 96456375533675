import React from 'react'
import {Link, graphql} from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import ArticleList from "../components/article-list";
import Witticism from "../components/witticism";

const IndexEnPage = (response) => {
  return (
      <Layout lang="en" pagePath="">
        <SEO title="HOME" keywords={[`Pikon log`, `Pikon Inc.`, `Tech Blog`]} lang="en" description="Technical notes written by members of Pikon Inc."/>
        <Witticism lang="en"/>
        <ArticleList
            blogs={response.data.allStrapiBlog.edges}
            type="enumeration"
            lang="en"
            heading="Recent Posts"
        />
      </Layout>
  )
}

export default IndexEnPage

export const pageQuery = graphql`  
  query IndexEnQuery {
    allStrapiBlog(
      filter:{
        isTranslated:{
          eq:true
        },
        status:{
          eq:true
        }
      },
      sort:{
        fields: [publishDate, id]
  	    order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          id
          title
          title_en
          publishDate(formatString: "MMM DD, YYYY")
          strapiId
          slug
          isTranslated
          category {
            id
            name
            name_en
            slug
          }
          featuredImage {
            id
            childImageSharp{
              fixed(width: 160,height: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`